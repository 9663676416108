import React, { useState } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import { SearchInput } from "components";
import DatePicker from "react-datepicker";
import WebService from "../../../../api/WebServices";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";

import { GetDate, GetDescription, GetType } from "helpers/LogHelper";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const ActivityTable = (props) => {
  const { className, history, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState([]);
  const [userId, setUserId] = useState("");
  const criteriaList = [
    "All Actions",
    "Logged In",
    "Active Offer",
    "Complete Offer",
    "Transaction Created",
    "Created Offer",
    "Edited Offer",
    "Changed Email",
    "Changed Password",
  ];

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  var RequestObject = {
    IntTag: 0,
    StringTag: "",
  };

  const handleChange = (event) => {
    event.persist();

    setUserId(userId + event.nativeEvent.data);
  };
  const handleClick = (event) => {
    //Search for users activity

    console.log(userId);
    if (!isNaN(userId)) {
      RequestObject.StringTag = "";
      RequestObject.IntTag = userId;
    } else {
      RequestObject.StringTag = userId;
      RequestObject.IntTag = 0;
    }

    console.log(RequestObject);
    const fetchData = async () => {
      const result = await WebService.GetUserLogs(history, RequestObject);
      if (result != null) {
        console.log(result);
        setLogs(result);
      } else {
        history.push({ pathname: "./sign-in" });
      }
    };
    fetchData();
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Enter UserId"
          onChange={handleChange}
        />
        {"  "}
        <Button color="primary" variant="contained" onClick={handleClick}>
          Search
        </Button>
        {"  "}
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {true ? "Add Filter" : "Multiple Item Selected"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {criteriaList.map((value) => (
              <Dropdown.Item>{value}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <DatePicker />
      </div>

      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Time Stamp</TableCell>
                    <TableCell>Activity Type</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.slice(0, rowsPerPage).map((log) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={""}
                      onClick={""}
                      selected={""}
                    >
                      <TableCell>{GetDate(log)}</TableCell>
                      <TableCell>{GetType(log)}</TableCell>
                      <TableCell>{GetDescription(log)}</TableCell>
                      <TableCell>{"Success"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={logs.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

ActivityTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  history: PropTypes.object,
};

export default withAuthorization(ActivityTable);
