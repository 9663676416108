import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import StorageServices from "../../api/StorageServices.js";
import PropTypes from "prop-types";
import StoredImagesTable from './Components/StoredImagesTable'
import StoredImagesToolbar from './Components/StoredImagesToolbar'
import ImagePreviewGrid from './Components/ImagePreviewGrid'
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import SessionHelper from "../../helpers/SessionHelper";
import ImageUploading from 'react-images-uploading';
import { storage } from "../../firebase";

import { 
    GetCompanyName,
    GetCompanyId
  } from '../../helpers/CompanyHelper'

import { 
    GetStoredImageId,
    GetImageURL,
    GetStoredImageIsActive,
    GetStoredImageIsDefault,
    GetStoredImageClient,
    BANNER,
    PROFILE,
    OFFER,
    OTHER,
    BANNER_INT,
    PROFILE_INT,
    OFFER_INT,
    OTHER_INT
  } from '../../helpers/StoredImageHelper'

  import {
    colors
  } from "@mui/material";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import {
    Button
  } from "reactstrap";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2),
    },
    imageBackground: {
        backgroundColor: colors.grey[100]
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    imageContainer: {
        height: 100,
        width: 300,
        margin: "0 auto",
        marginBottom: "20px",
        borderRadius: "1px",
        overflow: "hidden",
        display: "flex",
        alignItems: "normal",
        justifyContent: "center",
        backgroundColor: colors.grey[100]
      },
      image: {
        width: "100%",
        objectFit : "contain"
      }
}));

const StoredImageList = props => {
    const { history, ...rest } = props;
    const classes = useStyles();
    const [storedImages, setStoredImages] = useState([]);
    const [filteredStoredImages, setFilteredStoredImages] = useState([]);


    const [showEditStoredImage, setShowEditStoredImage] = useState(false);
    const handleCloseEditStoredImage = () => setShowEditStoredImage(false);
    const handleShowEditStoredImage = (theStoredImage) => {
        console.log("The StoredImage: " + theStoredImage.Name);
        setStoredImageIdInput(GetStoredImageId(theStoredImage));
        setStoredImageOfferIdInput(0);
        setStoredImageImgUrlInput(GetImageURL(theStoredImage));
        setStoredImageIsActiveInput(GetStoredImageIsActive(theStoredImage));
        setStoredImageIsDefaultInput(GetStoredImageIsDefault(theStoredImage));
        setStoredImageOrderInput(0);
        setShowEditStoredImage(true);
    }

    const [StoredImageOfferIdInput, setStoredImageOfferIdInput] = useState(0);
    const [StoredImageIdInput, setStoredImageIdInput] = useState(0);
    const [StoredImageImgUrlInput, setStoredImageImgUrlInput] = useState("");
    const [StoredImageIsActiveInput, setStoredImageIsActiveInput] = useState(false);
    const [StoredImageIsDefaultInput, setStoredImageIsDefaultInput] = useState(false);
    const [StoredImageOrderInput, setStoredImageOrderInput] = useState(0);

    const [picturesInput, setPictures] = useState([]);

    const storedImageOfferIdString = 'storedImageOfferIdInput';
    const storedImageCompanyIdString = 'storedImageCompanyIdInput';
    const storedImageImageUrlString = 'storedImageImageUrlInput';
    const storedImageIsActiveString = 'storedImageIsActiveInput';
    const storedImageIsDefaultString = 'storedImageIsDefualtInput';
    const storedImageOrderString = 'storedImageOrderInput';

    const onDrop = (imageList, addUpdateIndex) => {
        if(imageList !== null && imageList.length > addUpdateIndex){
            var img =  imageList[addUpdateIndex]

            var image = new Image();
    
            //Set the Base64 string return from FileReader as source.
            image.src = img['data_url'];
            //Validate the File Height and Width.
            image.onload = function () {
                var height = this.height;
                var width = this.width;
    
                if (height/width > 1.1 || height/width < 0.9) {
                alert("Recommended Ratio for images is 1:1");
                return false;
                }
                return true;
            };
            if(picturesInput.length > 0)
                picturesInput.pop()
            setPictures(
                picturesInput.concat(img)
            );
        }
        
    }

    const [showAddStoredImage, setShowAddStoredImage] = useState(false);
    const [showViewStoredImages, setShowViewStoredImages] = useState(false);
    const handleCloseAddStoredImage = () => setShowAddStoredImage(false);
    const handleCloseViewStoredImages = () => setShowViewStoredImages(false);
    const handleShowAddStoredImage = () => {
        
        setShowAddStoredImage(true);
        setStoredImageOfferIdInput(0);
        setStoredImageIdInput(0);
        setStoredImageImgUrlInput("");
        setStoredImageIsActiveInput(false);
        setStoredImageIsDefaultInput(false);
        setStoredImageOrderInput(0);
        setPictures([]);
        setSelectedCompany(0);
    }

    const onImageSelected = (imageId) => {
        console.log("Image selected Id: "+ imageId)
    }

    const handleShowViewStoredImages = () => {
        
        setShowViewStoredImages(true);
        
    }

    const handleCreateStoredImage = (storedImageUrl, uploadedName) => {
        console.log("Send StoredImage")
        console.log("StoredImage request:");
        console.log(selectedCompany);
        console.log("Create StoredImage Param: "+storedImageUrl);
        const imageNameA = uploadedName;
        console.log(imageNameA);
        console.log(PROFILE_INT);
        console.log(SessionHelper.getCompanyId());
        WebService.CreateStoredImage(history, storedImageUrl, imageNameA, PROFILE_INT, selectedCompany, StoredImageIsDefaultInput)
        .then(data => {
            console.log("ABC DONE");
            console.log("Data: "+data);
            if(data != null) {
                // Temporary sorting solution:
                data.sort((firstStoredImage, secondStoredImage) =>
                    (GetStoredImageId(firstStoredImage) != null ? GetStoredImageId(firstStoredImage) : 0) -
                    (GetStoredImageId(secondStoredImage) != null ? GetStoredImageId(secondStoredImage) : 0));
                setStoredImages(data)
                handleCloseAddStoredImage();
            }else{
                StorageServices.handleDelete(selectedCompany, imageNameA, PROFILE, null)
                handleCloseAddStoredImage()
            }
        });
    }

    const handleEditStoredImage = () => {
        console.log("Edit StoredImage")
        console.log(StoredImageIdInput);
        if(StoredImageIdInput != 0) {
          WebService.ModifyStoredImage(history, StoredImageIdInput, StoredImageIsActiveInput, StoredImageIsDefaultInput, false)
            .then(data => {
                console.log("ABC DONE");
                console.log("Data: "+data);
                if(data != null) {
                    
                    handleCloseEditStoredImage();
                    // Temporary sorting solution:
                    data.sort((firstStoredImage, secondStoredImage) =>
                    (GetStoredImageId(firstStoredImage) != null ? GetStoredImageId(firstStoredImage) : 0) -
                    (GetStoredImageId(secondStoredImage) != null ? GetStoredImageId(secondStoredImage) : 0));
                    setStoredImages(data)
                }else{
                    handleCloseEditStoredImage()
                }
            });
        }
    }

    const handleUploadCall = () => {
        StorageServices.handleUpload(picturesInput[0], selectedCompany, PROFILE, uploadSuccess)
    }

    const uploadSuccess = (url, uploadedName) => {
        console.log("Inside upload Success: " + url);
        console.log(url);
        setStoredImageImgUrlInput(url);
        console.log(StoredImageImgUrlInput);
        handleCreateStoredImage(url,uploadedName);
    }

    const myChangeHandler = (event) => {
        console.log("Name:"+event.target.name);
        console.log("Value:"+event.target.value);
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === storedImageOfferIdString){
            setStoredImageOfferIdInput(val);
        }
        /*else if(nam === storedImageImageUrlString){
            setStoredImageImgUrlInput(val);
        }*/else if(nam === storedImageIsActiveString){
            setStoredImageIsActiveInput(event.target.checked);
        }else if(nam === storedImageIsDefaultString){
            setStoredImageIsDefaultInput(event.target.checked);
        }else if(nam === storedImageOrderString){
            setStoredImageOrderInput(val);
        }else if(nam === storedImageCompanyIdString){
            setSelectedCompany(val);
        }
    }

    const handleGetCompanyName = (companyId) => {
        if(companyId != null && companies != null){
            const companyFound = companies.find(element => GetCompanyId(element) == companyId);
            if(companyFound != null){
                return GetCompanyName(companyFound);
            }
        }
        return "-";
    }

    const sortCompaniesFunc = (companyId) => {
        setSelectedCompanyFilter(companyId)
    }
    const [selectedCompanyFilter, setSelectedCompanyFilter] = useState(0);

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(0);

    const applyAllFilters = (image) => {
        return filterCompanies(image) && GetStoredImageIsActive(image);
    }

    const filterCompanies = (image) => {
        if(selectedCompanyFilter != 0){
          return GetStoredImageClient(image) == selectedCompanyFilter;
        }else{
            return true;
        }
    }

    useEffect(() => {
        if(storedImages != null){
            setFilteredStoredImages(storedImages.filter(applyAllFilters))
        }
    }, [storedImages, selectedCompanyFilter]);

    useEffect(() => {
            const fetchData = async () => {
                const result = await WebService.GetAllCompanies(history, undefined);
                if (result != null) {
                    // Temporary sorting solution:
                    result.sort((firstCompany, secondCompany) =>
                    GetCompanyName(firstCompany).localeCompare(GetCompanyName(secondCompany)));
                    setCompanies(result)
                } else {
                }
            };
            fetchData();
    }, []);

    useEffect(() => {
        if(SessionHelper.isMasterAdmin()) {
            const fetchData = async () => {
                const result = await WebService.GetAllStoredImages(history, 0, false);
                if (result != null) {
                    // Temporary sorting solution:
                    result.sort((firstStoredImage, secondStoredImage) =>
                       (GetStoredImageId(firstStoredImage) != null ? GetStoredImageId(firstStoredImage) : 0) -
                       (GetStoredImageId(secondStoredImage) != null ? GetStoredImageId(secondStoredImage) : 0));
                    setStoredImages(result)
                } else {
                    history.push({pathname: "./sign-in"});
                }
            };
            fetchData();
        }
    }, []);

    return (
      <div className={classes.root}>
          <ImagePreviewGrid 
          tileData={storedImages} 
          showViewStoredImages={showViewStoredImages} 
          handleCloseViewStoredImages={handleCloseViewStoredImages}
          onImageSelected={onImageSelected}
          setImageData={setStoredImages}/>
          
        
        <Modal
            className={classes.content}
            show={showAddStoredImage}
            onHide={handleCloseAddStoredImage}
            centered>
            <Modal.Header closeButton>
            <Modal.Title>{"Upload Image"}</Modal.Title>
            </Modal.Header>
            <Modal.Body><div>{"Image You Want to Upload"}</div>
            <Form className={classes.imageBackground}>
                <Form.Group controlId="formBasicEmail" >
                    <Form.Label>Select the Company you want to add the Image to</Form.Label>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        id="inlineFormCustomSelectPref"
                        custom
                        name={storedImageCompanyIdString}
                        onChange = {myChangeHandler}
                    >
                        <option value={0}>All</option>
                        {companies.map((aNum) => (
                        <option value={GetCompanyId(aNum)} key={GetCompanyId(aNum)}>{GetCompanyName(aNum)}</option>
                        ))}
                    </Form.Control>
                    <Form.Label>Active?</Form.Label>
                    <Form.Check required placeholder="Is the StoredImage Active" name={storedImageIsActiveString} onChange={myChangeHandler}/>
                    <Form.Text className="text-muted">
                    </Form.Text>
                    <Form.Label>Set Default</Form.Label>
                    <Form.Check required placeholder="Is the StoredImage Default" name={storedImageIsDefaultString} onChange={myChangeHandler}/>
                    
                </Form.Group>
                </Form>
                <ImageUploading
                    multiple={false}
                    value={picturesInput}
                    onChange={onDrop}
                    maxNumber={1}
                    maxFileSize={5242880}
                    acceptType={['jpg', 'gif', 'png', 'gif']}
                    dataURLKey="data_url">
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          {picturesInput.length == 0 && <button
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click or Drop here
                          </button>}
                          &nbsp;
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image['data_url']} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button onClick={() => onImageUpdate(index)}>Update</button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                {/* <ImageUploader
                singleImage={true}
                withPreview={true}
                withIcon={true}
                buttonText='Choose images'
                onChange={onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
            /> */}
                </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleUploadCall}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleCloseAddStoredImage}>
                Cancel
            </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            className={classes.content}
            show={showEditStoredImage}
            onHide={handleCloseEditStoredImage}
            centered>
            <Modal.Header closeButton>
            <Modal.Title>{"Edit StoredImage"}</Modal.Title>
            </Modal.Header>
            <Modal.Body><div>{"StoredImage You Want to Modify"}</div>
            <div className={classes.imageContainer}>
                          <img
                            alt="Product"
                            className={classes.image}
                            
                            src={StoredImageImgUrlInput}
                          />
                        </div>
            <Form >
                <Form.Group controlId="formBasicEmail" >
                    <Form.Label>Active?</Form.Label>
                    <Form.Check required placeholder="Is the StoredImage Active" name={storedImageIsActiveString} defaultChecked={StoredImageIsActiveInput} onChange={myChangeHandler}/>
                    <Form.Group controlId="formBasicCheckbox">
                </Form.Group>
                    <Form.Text className="text-muted">
                    
                    </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" >
                    <Form.Label>Is Default</Form.Label>
                    <Form.Check required placeholder="Is the StoredImage Default" name={storedImageIsDefaultString} defaultChecked={StoredImageIsDefaultInput} onChange={myChangeHandler}/>
                    <Form.Group controlId="formBasicCheckbox">
                </Form.Group>
                </Form.Group>
                </Form>
               
                </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleEditStoredImage}>
                Yes
            </Button>
            <Button variant="secondary" onClick={handleCloseEditStoredImage}>
                Cancel
            </Button>
            </Modal.Footer>
        </Modal>
        <StoredImagesToolbar allCompanies={companies} sortCompanies={sortCompaniesFunc} history={history} openShowStoredImagesPopup ={handleShowViewStoredImages} openAddStoredImagePopup={handleShowAddStoredImage}/>
        <div className={classes.content}>
            <StoredImagesTable StoredImages={filteredStoredImages} GetCompanyName={handleGetCompanyName} openEditStoredImagePopup={handleShowEditStoredImage}/>
        </div>
      </div> 
    );
};

StoredImageList.propTypes = {
    history: PropTypes.object
};

export default withMasterAuthorization(StoredImageList);
