import React, { useState } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  colors
} from "@mui/material";

import { GetMyOfferDetails } from "api/WebServices";
import { 
  GetCompanyId,
  GetCompanyName,
  GetURL, 
  GetDateCreated,
  GetImageURL
} from '../../../../helpers/CompanyHelper'
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  imageContainer: {
    height: 64,
    width: 64,
    margin: "0 auto",
    marginBottom: "20px",
    borderRadius: "1px",
    overflow: "hidden",
    display: "flex",
    alignItems: "normal",
    justifyContent: "center",
  },
  tableRow: {
    // height: 10,
    // padding:0,
    backgroundColor: colors.grey[50]
  },
  imageView: {
    width: "100%",
    objectFit : "contain"
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const CompaniesTable = props => {
  const { className, Companies, history, Company, ...rest } = props;

  const classes = useStyles();

  const selectedCompany = useState();

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { Companies } = props;

    let selectedCompanies;

    if (event.target.checked) {
      selectedCompanies = Companies.map(Company => Company.Id);
    } else {
      selectedCompanies = [];
    }

    setSelectedCompanies(selectedCompanies);
  };

  const handleSelectedCompany = (event, Companies) => {
    
    history.push({ pathname: "./AdminDetails", state: { Company: Companies} });
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCompanies.indexOf(id);
    let newSelectedCompanies = [];

    if (selectedIndex === -1) {
      newSelectedCompanies = newSelectedCompanies.concat(selectedCompanies, id);
    } else if (selectedIndex === 0) {
      newSelectedCompanies = newSelectedCompanies.concat(selectedCompanies.slice(1));
    } else if (selectedIndex === selectedCompanies.length - 1) {
      newSelectedCompanies = newSelectedCompanies.concat(selectedCompanies.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCompanies = newSelectedCompanies.concat(
        selectedCompanies.slice(0, selectedIndex),
        selectedCompanies.slice(selectedIndex + 1)
      );
    }

    setSelectedCompanies(newSelectedCompanies);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell></TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Date Added</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Companies.slice(0, rowsPerPage).map(Company => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={GetCompanyId(Company)}
                    onClick={() => props.openEditCompanyPopup(Company)}
                    selected={selectedCompanies.indexOf(GetCompanyId(Company)) !== -1}
                  >
                    <TableCell>
                        {" "}
                        <div className={classes.imageContainer}>
                          <img
                            alt="Product"
                            className={classes.imageView}
                            
                            src={GetImageURL(Company)}
                          />
                        </div>
                      </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{GetCompanyName(Company)}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{GetURL(Company)}</TableCell>
                    <TableCell>{}</TableCell>
                    <TableCell>{GetDateCreated(Company)}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={Companies.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

CompaniesTable.propTypes = {
  className: PropTypes.string,
  Companies: PropTypes.array.isRequired,
  history: PropTypes.object,
  Company: PropTypes.object
};

export default withMasterAuthorization(CompaniesTable);
