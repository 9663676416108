import React, { useEffect, useState } from 'react'
import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Avatar,
  Tooltip,
  TableSortLabel
} from "@mui/material";
import Paper from '@mui/material/Paper'
import InnerCollapsiblePayoutTable from '../PaypalWebhookResponseRow'

import {
  GetEventType,
  GetPaypalId,
  GetResourceType,
  GetTimeCreated, 
  GetUserPayouts
} from 'helpers/PaypalWebhookResponseHelper'

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const PaypalWebhookResponseTable = props => {
  const { className, PaypalWebhookResponses,
    history, ...rest } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  const sortTypeList = [
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE

  ];
  const dataRetrieveFunctionList = [
    GetPaypalId, 
    GetPaypalId, 
    GetResourceType, 
    GetResourceType, 
    GetEventType, 
    GetEventType, 
    GetEventType,
    GetEventType,
    GetTimeCreated,
    GetTimeCreated
  ];
  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, PaypalWebhookResponses, sortTypeList, dataRetrieveFunctionList, GetPaypalId, undefined, setSelectedSort) 
  };

  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                    <TableCell/> 
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                          Paypal Id
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                          Resource Type
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL3_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL3_ASC) || handleIsSortActive(COLUMNS_SORT.COL3_DESC)}>
                          Event Type
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL4_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                          Summary
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL5_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL5_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL5_ASC) || handleIsSortActive(COLUMNS_SORT.COL5_DESC)}>
                          Date Created by Paypal
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PaypalWebhookResponses.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map(webhookResponse => (
                 <InnerCollapsiblePayoutTable
                  PaypalWebhookResponse={webhookResponse}
                  history={history}
                 /> 
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={PaypalWebhookResponses.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

PaypalWebhookResponseTable.propTypes = {
  className: PropTypes.string,
  PaypalWebhookResponses: PropTypes.array.isRequired,
  history: PropTypes.object
};

export default withRouter(PaypalWebhookResponseTable);
