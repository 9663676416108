import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

import withAuthorization from "../../../../helpers/Authorization";
import { 
  GetInstitutionName, 
  GetAccountName, 
  GetAccountMask, 
  GetAccountSubtype,
  GetBankGroupId,
  GetBetfullyBankAccountId } from "../../../../helpers/BankAccountHelper";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const statusColors = {
  delivered: "success",
  pending: "info",
  refunded: "danger",
};

const UserBanks = (props) => {
  const { className, bankAccounts, onBankSelected, onRefreshBank, onRefreshTransactions, ...rest } = props;

  const classes = useStyles();
  console.log("Bank: "+bankAccounts);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [filteredBankAccounts, setFilteredBankAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.STR
  ];
  const dataRetrieveFunctionList = [
    GetInstitutionName, 
    GetAccountName, 
    GetAccountMask, 
    GetAccountSubtype
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, bankAccounts, sortTypeList, dataRetrieveFunctionList, GetBetfullyBankAccountId, applyAllFilters, setSelectedSort) 
  };
  
  useEffect(() => {
    console.log("Use effect called")
    applyAllFilters();
    setPage(0)
  },[bankAccounts]);

  const applyAllFilters = () => {
    var allBankAccountsVar = bankAccounts;
    if(allBankAccountsVar == null){
      allBankAccountsVar = [];
    }
    setFilteredBankAccounts(allBankAccountsVar)
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <Button color="primary" size="small" variant="text">
        //     View all <ArrowRightIcon />
        //   </Button>
        // }
        title="User Bank Accounts"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.COL1_ASC) || isSortActive(COLUMNS_SORT.COL1_DESC)}>
                        Institution
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.COL2_ASC) || isSortActive(COLUMNS_SORT.COL2_DESC)}>
                        AccountName
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL3_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.COL3_ASC) || isSortActive(COLUMNS_SORT.COL3_DESC)}>
                        Mask
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL4_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.COL4_ASC) || isSortActive(COLUMNS_SORT.COL4_DESC)}>
                        Subtype
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell>Refresh</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              { !isLoading && (filteredBankAccounts == null || filteredBankAccounts.length == 0) && <TableRow
                        className={classes.tableRow}
                        hover
                        key={-1}
                      >
                    <TableCell></TableCell>
                    <TableCell>{"No Data Available"}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>}
                {!isLoading && (filteredBankAccounts != null && filteredBankAccounts.length > 0) && filteredBankAccounts.slice(page * rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((order) => (
                  <TableRow hover key={GetBetfullyBankAccountId(order)}
                  >
                    <TableCell onClick={() => onBankSelected(GetBetfullyBankAccountId(order))}>{GetInstitutionName(order)}</TableCell>
                    <TableCell onClick={() => onBankSelected(GetBetfullyBankAccountId(order))}>{GetAccountName(order)}</TableCell>
                    <TableCell onClick={() => onBankSelected(GetBetfullyBankAccountId(order))}>{GetAccountMask(order)}</TableCell>
                    <TableCell onClick={() => onBankSelected(GetBetfullyBankAccountId(order))}>{GetAccountSubtype(order)}</TableCell>
                    <TableCell>
                          <Button 
                            color="primary" 
                            onClick={() => 
                              onRefreshBank(GetBankGroupId(order))} >
                              Force Relink Account
                          </Button>
                          <Button 
                            color="primary" 
                            onClick={() => 
                              onRefreshTransactions(GetBankGroupId(order))} >
                              Refresh Transactions
                          </Button>
                        </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filteredBankAccounts.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
    </Card>
  );
};

UserBanks.propTypes = {
  className: PropTypes.string,
};

export default withAuthorization(UserBanks);
