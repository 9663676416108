import React, { useState } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import PerfectScrollbar from "react-perfect-scrollbar";
import Checkbox from '@mui/material/Checkbox'
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Avatar,
  Tooltip,
  TableSortLabel
} from "@mui/material";

import { GetMyOfferDetails } from "api/WebServices";
import { 
  GetEmail,
  GetUserName,
  GetAdminUserId, 
  GetDateCreated,
  GetUserRole, 
  GetUserCompany
} from '../../../../helpers/AdminUserHelper'

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));


const AdminsTable = props => {
  const { className, Admins, history, Admin, ...rest } = props;

  const classes = useStyles();

  const selectedAdmin = useState();

  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);


  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR
  ];

  const dataRetrieveFunctionList = [
    GetUserName, 
    GetUserName, 
    GetEmail, 
    GetEmail, 
    GetUserCompany, 
    GetUserCompany, 
    GetUserRole,
    GetUserRole,
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, Admins, sortTypeList, dataRetrieveFunctionList, GetAdminUserId, undefined, setSelectedSort) 
  };
  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  const handleSelectAll = event => {
    const { Admins } = props;

    let selectedAdmins;

    if (event.target.checked) {
      selectedAdmins = Admins.map(Admin => Admin.id);
    } else {
      selectedAdmins = [];
    }

    setSelectedAdmins(selectedAdmins);
  };

  const handleSelectedAdmin = (event, Admins) => {
    history.push({ pathname: "./AdminDetails", state: { Admin: Admins} });
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedAdmins.indexOf(id);
    let newSelectedAdmins = [];

    if (selectedIndex === -1) {
      newSelectedAdmins = newSelectedAdmins.concat(selectedAdmins, id);
    } else if (selectedIndex === 0) {
      newSelectedAdmins = newSelectedAdmins.concat(selectedAdmins.slice(1));
    } else if (selectedIndex === selectedAdmins.length - 1) {
      newSelectedAdmins = newSelectedAdmins.concat(selectedAdmins.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedAdmins = newSelectedAdmins.concat(
        selectedAdmins.slice(0, selectedIndex),
        selectedAdmins.slice(selectedIndex + 1)
      );
    }

    setSelectedAdmins(newSelectedAdmins);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                      User Name
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                      Email
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL3_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL3_ASC) || handleIsSortActive(COLUMNS_SORT.COL3_DESC)}>
                      Company
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL4_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                      Role
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  {//<TableCell>Active</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {Admins.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map(Admin => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={GetAdminUserId(Admin)}
                   // onClick={event => handleSelectedAdmin(event, Admin)}
                   // selected={selectedAdmins.indexOf(GetAdminUserId(Admin)) !== -1}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{GetUserName(Admin)}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{GetEmail(Admin)}</TableCell>
                    <TableCell>{GetUserCompany(Admin)}</TableCell>
                    <TableCell>{GetUserRole(Admin)}</TableCell>
                    <TableCell>     
                     {// <Checkbox
                       // defaultChecked
                       // color="primary"
                       // inputProps={{ 'aria-label': 'secondary checkbox' }}
                     // />
                     }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={Admins.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

AdminsTable.propTypes = {
  className: PropTypes.string,
  Admins: PropTypes.array.isRequired,
  history: PropTypes.object,
  Admin: PropTypes.object
};

export default withMasterAuthorization(AdminsTable);
