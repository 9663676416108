import React, { useEffect, useState } from 'react'
import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from "@mui/material";
import {
  GetPaypalPayoutItemFee,
  GetPaypalPayoutItemId, GetPaypalPayoutItemStatus,
  GetPaypalPayoutItemTransactionId,
  GetReceiverEmail,
  GetTimeProcessed
} from '../../../../../helpers/PaypalPayoutItemHelper'
import { GetRecieverEmailAddress } from '../../../../../helpers/UserPayoutHelper'
import withMasterAuthorization from "../../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const PaypalPayoutItemTable = props => {
  const { className, PaypalPayoutItems,
    history, ...rest } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleClickedWebhookResponse = (event, PaypalPayoutItem) => {
    event.stopPropagation();

  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Paypal Item Id</TableCell>
                  <TableCell>Paypal Transaction Id</TableCell>
                  <TableCell>Transaction Status</TableCell>
                  <TableCell>Paypal Fee</TableCell>
                  <TableCell>Receiver Email</TableCell>
                  <TableCell>Date Processed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PaypalPayoutItems.slice(0, rowsPerPage).map(PaypalPayoutItem => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={GetPaypalPayoutItemId(PaypalPayoutItem)}
                  >
                    <TableCell>{GetPaypalPayoutItemId(PaypalPayoutItem)}</TableCell>
                    <TableCell>{GetPaypalPayoutItemTransactionId(PaypalPayoutItem)}</TableCell>
                    <TableCell>{GetPaypalPayoutItemStatus(PaypalPayoutItem)}</TableCell>
                    <TableCell>{GetPaypalPayoutItemFee(PaypalPayoutItem)}</TableCell>
                    <TableCell>{GetReceiverEmail(PaypalPayoutItem)}</TableCell>
                    <TableCell>{GetTimeProcessed(PaypalPayoutItem)}</TableCell>
                  </TableRow>
                )
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={PaypalPayoutItems.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

PaypalPayoutItemTable.propTypes = {
  className: PropTypes.string,
  PaypalPayoutItems: PropTypes.array.isRequired,
  history: PropTypes.object
};

export default withMasterAuthorization(PaypalPayoutItemTable);
