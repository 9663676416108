import SessionHelper from "helpers/SessionHelper"
import { storage } from "../firebase";
import { PROFILE, BANNER, OFFER, OTHER } from "../helpers/StoredImageHelper"
import moment from "moment";

class StorageServices {
  constructor() {
    if (!StorageServices.instance) {
      console.log("Inside the storageServices file: ");

      

      StorageServices.instance = this;
      console.log("initialized storageServices");
    }

    return StorageServices.instance;
  }

  
  handleUpload = (pictureToUpload, companyId, imageType, responseFunc) => {
    console.log("Upload func");
    if(pictureToUpload !== undefined){
        console.log("pictureToUpload undefined");
    }else if(pictureToUpload != null){
        console.log("pictureToUpload null");
    }else if(pictureToUpload.file.name != null){
        console.log("pictureToUpload.name null");
    }else if(companyId !== undefined){
      console.log("companyId undefined");
    }else if(companyId != null){
      console.log("companyId null");
    }else if(imageType !== undefined){
      console.log("imageType undefined");
    }else if(imageType != null){
      console.log("imageType null");
    }
    
    if(pictureToUpload !== undefined && pictureToUpload != null && pictureToUpload.file.name != null &&
      companyId !== undefined && companyId != null && imageType !== undefined && imageType != null){
        console.log("Upload func start");
        const timeStampedName = pictureToUpload.file.name;
        console.log("FileName: "+timeStampedName);

        const uploadTask = storage.ref(`images/${companyId}/${imageType}/${timeStampedName}`).put(pictureToUpload.file);
        uploadTask.on(
            "state_changed",
            snapshot => {},
            error => {
                console.log(error);
            },
            () => {
                storage
                    .ref("images")
                    .child(`${companyId}/${imageType}/${timeStampedName}`)
                    .getDownloadURL()
                    .then(url => {
                        console.log("Got url: " + url);
                        responseFunc(url, timeStampedName);
                    });
            }
        );
    }
  };

  handleDelete = (companyId, fileName, fileType, responseFunc) => {
    console.log("Delete func");
    console.log("Company Id: "+companyId);
    console.log("fileName: "+fileName);
    console.log("fileType: "+fileType);
    if(companyId != null && fileName != null && fileType != null){
        console.log("Delete func start");
        const deleteTask = storage.ref("images").child(`${companyId}/${fileType}/${fileName}`);
        deleteTask.delete().then(function(){
          console.log("File Deleted Successfully");
          if(responseFunc != null)
            responseFunc(true);
        }).catch(function(error){
          console.log("Delete Error: "+error);
          if(responseFunc != null)
            responseFunc(false);
        });
    }
  };

  getImageType = (intType) =>{
    switch(intType){
      case 1:
        return PROFILE;
      case 2:
        return BANNER;
      case 3:
        return OFFER;
    }
    return OTHER;
  }

}

const instance = new StorageServices();
export default instance;
