import React, { useState, useEffect  } from "react";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  TablePagination
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { GetClientNameFrom, GetOfferStatus } from "helpers/UserDetailsHelper";
import { 
  GetBetfullyTransactionId,
  GetPointsAwarded, 
  GetPlaidAmountFormatted,
  GetDateCreated, 
  GetPlaidName,
  GetPlaidDate,
  GetPlaidDateString,
  GetPlaidTransactionId,
  GetPlaidAmount,  
  GetBetfullyType,
  GetKeywordId} from "helpers/WebUserTransactionHelper";

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

import mockData from "./data";
import withAuthorization from "../../../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const statusColors = {
  delivered: "success",
  pending: "info",
  refunded: "danger",
};

const TransactionTable = (props) => {
  const { className, userdetails, isLoading, ...rest } = props;

  const classes = useStyles();

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM
  ];
  
  const dataRetrieveFunctionList = [
    GetPlaidTransactionId, 
    GetPlaidTransactionId, 
    GetDateCreated, 
    GetDateCreated, 
    GetBetfullyType, 
    GetBetfullyType, 
    GetPointsAwarded,
    GetPointsAwarded
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, filteredTransactions, sortTypeList, dataRetrieveFunctionList, GetBetfullyTransactionId, undefined, setSelectedSort) 
  };

  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  function GetList() {
    if (userdetails == null || userdetails.ListOfTransactions == null) {
      return [];
    } else {
      return userdetails.ListOfTransactions;
    }
  }

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [filteredTransactions, setFilteredTransactions] = useState([]);


  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  useEffect(()=> {
    console.log("Reload")
    setFilteredTransactions(GetList())
  }, [isLoading]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <Button color="primary" size="small" variant="text">
        //     View all <ArrowRightIcon />
        //   </Button>
        // }
        title="Point History"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                        Identifier
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                        BetFully Date
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL3_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL3_ASC) || handleIsSortActive(COLUMNS_SORT.COL3_DESC)}>
                        Type
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COL4_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                        active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                        Points Earned
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              { isLoading && <TableRow
                        className={classes.tableRow}
                        hover
                        key={-1}
                      >
                        <TableCell></TableCell>

                    <Row>
                      <Col ></Col>
                      <Col ></Col>
                      <Col md="auto"><Spinner animation="border" variant="primary" /></Col>
                    </Row>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>}
              {!isLoading && filteredTransactions.slice(page * rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((transaction) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={GetBetfullyTransactionId(transaction)+GetDateCreated(transaction)}
                    >
                      <TableCell>{GetPlaidTransactionId(transaction)}</TableCell>
                      <TableCell>{GetDateCreated(transaction)}</TableCell>
                      <TableCell>{GetBetfullyType(transaction)}</TableCell>
                      <TableCell>{GetPointsAwarded(transaction)}</TableCell>
                    </TableRow>
                  ))}
                {/* {GetList().map((order) => (
                  <TableRow hover key={order.id}>
                    <TableCell>{order.Date}</TableCell>
                    <TableCell>{GetClientNameFrom(order.Client)}</TableCell>
                    <TableCell>{"$" + order.Amount}</TableCell>
                    <TableCell>{order.PointsAwarded + " BFP"}</TableCell>
                    <TableCell>{"Processed"}</TableCell>
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filteredTransactions.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
    </Card>
  );
};

TransactionTable.propTypes = {
  className: PropTypes.string,
  userdetails: PropTypes.any,
};

export default withAuthorization(TransactionTable);
