import React, { useState } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Tooltip,
  TableSortLabel
} from "@mui/material";

import { 
  GetId, 
  GetBetfullyUserId,
  GetType,
  GetEventName,
  GetDescription, 
  GetDateString,
  GetDate,
  GetWebUser,
  GetUserName
} from "helpers/LogHelper";

import { 
  GetName,
  GetUserPoints
} from "helpers/UserHelper"

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const FraudShieldTable = (props) => {
  const { className, logs, history, log, ...rest } = props;

  const classes = useStyles();

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE,
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR
  ];
  const dataRetrieveFunctionList = [
    GetEventName, 
    GetEventName, 
    GetDescription, 
    GetDescription, 
    GetDate, 
    GetDate, 
    GetBetfullyUserId,
    GetBetfullyUserId,
    GetUserName,
    GetUserName
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, logs, sortTypeList, dataRetrieveFunctionList, GetId, undefined, setSelectedSort) 
  };
    
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  
  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  const handleSelectedUser = (event, usera) => {
    history.push({ pathname: "./userdetails", state: { user: usera } });
  };

  const handlePageChange = (event, page) => {
    console.log("Page "+page);
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                      Event Name
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell  onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                      Log
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL3_ASC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL3_ASC) || handleIsSortActive(COLUMNS_SORT.COL3_DESC)}>
                      Date
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  {/* <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL4_ASC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                      User Id
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell> */}
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL4_ASC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                      Name
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell >
                      Points (To Date)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((log) => (
                  <TableRow
                    onClick={(event) => handleSelectedUser(event, GetWebUser(log))}
                    className={classes.tableRow}
                    hover
                    key={GetId(log)}
                  >
                    <TableCell style={{ width: '30%' }}>{GetEventName(log)}</TableCell>
                    <TableCell style={{ width: '17%' }}>{GetDescription(log)}</TableCell>
                    <TableCell style={{ width: '12%' }}>{GetDateString(log)}</TableCell>
                    <TableCell style={{ width: '12%' }}>{GetUserName(log)}</TableCell>
                    <TableCell style={{ width: '12%' }}>{GetUserPoints(GetWebUser(log))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={logs.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

FraudShieldTable.propTypes = {
  className: PropTypes.string,
  logs: PropTypes.array.isRequired,
  history: PropTypes.object
};

export default withAuthorization(FraudShieldTable);
