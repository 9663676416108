import moment from "moment";
import React from "react";
import { NumericFormat } from 'react-number-format';
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Grid,
  IconButton,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CardActions,
  Avatar,
  Checkbox,
  Tooltip,
  TableSortLabel,
  Collapse,
  Paper,
  Box,
  colors
} from "@mui/material";
import SessionHelper from 'helpers/SessionHelper'
import {
  MaxDepositValues, 
  CashBackValues, 
  MaxDaysValues, 
  InEqualityValues, 
  MaxTransactionValues } from "constants/constants.js";

  import {
    GetOfferId,
    GetTitle,
    GetDescription,
    GetActivationCount,
    GetReward,
    GetOfferCriteriaCount,
    GetCashBackPercentage,
    GetOfferURL,
    GetTermsURL,
    GetExtraURL,
    GetCompanyIcon,
    GetCompanyId as GetOfferCompanyId
  } from "helpers/OfferHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  tableRow: {
    height: 10,
    padding:0
  },
  tableCell: {
    height: 15,
    //width: 150,
    paddingLeft:3,
    paddingRight:3,
    paddingTop:1,
    paddingBottom:1,
  },
  tableCellSmallText: {
    height: 10,
    width: 10,
    textAlign: 'center',
    paddingLeft:1,
    paddingRight:1,
    paddingTop:1,
    paddingBottom:1,
    fontSize: '10pt'
  },
  imageContainer: {
    height: 35,
    width: 50,
    margin: "auto",
    marginBottom: "0px",
    borderRadius: "1px",
    overflow: "hidden",
    display: "flex",
    alignItems: "normal",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    objectFit : "contain"
  },
  textContainer: {
    display: "block",
    width: "200px",
    overflow: "hidden",
  }
}));

const options = {
  Active: "Active",
  Paused: "Paused",
  Pending: "Pending",
  Removed: "Removed",
  Approved: "Approved",
  Expired: "Expired"
};

export function GetUserOfferId(product) {
  if (product.Id) {
    return product.Id;
  } else {
    return 0;
  }
}

export function GetOfferModelId(product) {
  if (product.OfferItemId) {
    return product.OfferItemId;
  } else {
    return 0;
  }
}


export function GetBetfullyUserId(product) {
  if (product.BetfullyUserId) {
    return product.BetfullyUserId;
  } else {
    return 0;
  }
}

export function GetStartDate(product) {
  if (product.StartDate) {
    return moment(product.StartDate).format("MMM DD, YYYY");
  } else {
    return "N/A";
  }
}

export function GetExpirationDate(product) {
  if (product.ExpirationDate) {
    return moment(product.ExpirationDate).format("MMM DD, YYYY");
  } else {
    return "N/A";
  }
}

export function GetDateClaimed(product) {
  if (product.DateClaimed) {
    return moment(product.DateClaimed).format("MMM DD, YYYY");
  } else {
    return "N/A";
  }
}

export function GetStatusString(offer) {
  switch (offer.Status) {
    case 0:
      return "Inactive";
    case 1:
      return "Active";
    case 2:
      return "Expired";
    case 3:
      return "Cancelled";
    case 4:
      return "Completed"
    default:
      return "n/a";
  }
}

export function GetOfferType(offerItemModel) {
  switch (offerItemModel.OfferType) {
    case 0:
      return "Cashback";
    case 1:
      return "SpecialOffer";
    case 2:
      return "FreeMoney";
    case 3:
      return "Referral";
    case 4:
      return "OfferWall"
    case 5:
      return "ShowAd";
    case 6:
      return "ReferralProgram";
    case 7:
      return "SignupOffer";
    case 8:
      return "WithdrawalOffer";
    case 9:
      return "InternalBetfullySignupOffer"
    case 10:
      return "SportbookLinkOffer";
    case 11:
      return "SportbookSingleLinkOffer";
    case 12:
      return "Deposit_CB";
    case 13:
      return "Withdrawal_CB";
    case 14:
      return "OpenPartnerAccountDeposit_CB"
    case 15:
      return "OpenPartnerAccountDepositPlaceWager_CB";
    case 16:
      return "Deposit_Instant";
    case 17:
      return "Withdrawal_Instant";
    case 18:
      return "OpenPartnerAccountDeposit_Instant";
    case 19:
      return "OpenPartnerAccountDepositPlaceWager_Instant"
    default:
      return "n/a";
  }
}

export function GetAmountProcessed(product) {
  if (product.AmountProcessed) {
    return product.AmountProcessed;
  } else {
    return 0;
  }
}

export function GetOfferModel(product) {
  if (product.mOfferItem) {
    return product.mOfferItem;
  } else {
    return null;
  }
}

export function GetOfferTransactions(product) {
  if (product.OfferTransactions) {
    return product.OfferTransactions;
  } else {
    return [];
  }
}

export function ContainsPending(product){
  var transactiosn = GetOfferTransactions(product);
  if (transactiosn.some(e => e.PendingApproval === true)) {
    /* vendors contains the element we're looking for */
    return true;
  }
  return false;
}




const CreateLocationRow = (location, approveFunc) => {
  return (
    <Grid container spacing={4}>
                <Grid align="left" item xs={4}>
                  {location.PendingApproval && <div>{"⚠️ PendingApproval!"}</div>}
                  <NumericFormat displayType={'text'} value={location.AmountProcessed} 
                  thousandSeparator={true} 
                  prefix={'Amount Processed: $'} />
                  <div></div>
                  <NumericFormat displayType={'text'}  value={location.PointsAwarded} 
                  thousandSeparator={true} prefix={'Points Awarded: '}
                  suffix={' pts'} />
                    <div>Date Created: {moment(location.DateCreated).format("MMM DD, YYYY")}</div>
                    {location.PendingApproval && <Button
                    onClick={() => approveFunc(location.Id)}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Approve
                  </Button>}
                </Grid> 
              </Grid>
  )
}

export function OfferRow(props) {
  const { row, approveTransaction, markUserOfferAsComplete } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const offerItem = GetOfferModel(row);
  return (
    <React.Fragment>
      {/* <TableRow className={classes.root}>
        
        <TableCell component="th" scope="row">
          {GetPlaidName(row)}
        </TableCell>
        <TableCell align="left">{GetPlaidAmountFormatted(row)}</TableCell>
        <TableCell align="left">{GetPlaidDate(row)}</TableCell>
        <TableCell align="left">{GetStatus(row)}</TableCell>
      </TableRow> */}
      <TableRow

        // onClick={(event) => handleShowOfferDetail(row)}
        className={classes.tableRow}
          key={GetUserOfferId(row)}
        >
          <TableCell>
        {ContainsPending(row)?"⚠️":""}
          </TableCell>
          <TableCell>
            {" "}
           {offerItem != null && <div className={classes.imageContainer}>
              <img
                alt="Product"
                className={classes.image}
                
                src={offerItem.CompanyImageUrl}
              />
            </div>}
          </TableCell>
          <TableCell className={classes.tableCell} >{(offerItem != null)?offerItem.Id:""}</TableCell>
          <TableCell className={classes.tableCell} >{(offerItem != null)?offerItem.ClientName:""}</TableCell>
          <TableCell className={classes.tableCell} >{(offerItem != null)?offerItem.Title:""}</TableCell>
          <TableCell className={classes.tableCell} >{(offerItem != null)?offerItem.Title2:""}</TableCell>
          <TableCell className={classes.tableCell}  >{(offerItem != null)?offerItem.ShortDescription:""}</TableCell>
          <TableCell className={classes.tableCell}  >{(offerItem != null)?GetOfferType(offerItem):''}</TableCell>
          <TableCell className={classes.tableCellSmallText}>{
            (offerItem != null)?
            <NumericFormat displayType={'text'}  value={offerItem.Reward} 
                  thousandSeparator={true} prefix={''}
                  suffix={' pts'} />
            :''}
          </TableCell>
          <TableCell className={classes.tableCellSmallText}>{<NumericFormat displayType={'text'}  value={row.TotalPointsAwarded} 
            thousandSeparator={true} prefix={''}
            suffix={' pts'} />}</TableCell>
          <TableCell className={classes.tableCellSmallText}>{<NumericFormat displayType={'text'}  value={GetAmountProcessed(row)} 
            thousandSeparator={true} prefix={'$'}
            suffix={''} />}</TableCell>
          <TableCell className={classes.tableCellSmallText}>{GetStartDate(row)}</TableCell>
          <TableCell className={classes.tableCellSmallText}>{GetExpirationDate(row)}</TableCell>
          <TableCell className={classes.tableCellSmallText}>{GetDateClaimed(row)}</TableCell>
          <TableCell className={classes.tableCellSmallText}>{GetStatusString(row)}</TableCell>
          
          
          {/* <TableCell className={classes.tableRow}> {renderSwitch(row)}</TableCell> */}
          <TableCell className={classes.tableRow}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            
            
            
            {GetOfferTransactions(row) != null &&
                <Box margin={5}>
                  <Typography variant="h4" gutterBottom component="div">
                    Transactions
                  </Typography>
                  <Paper className={classes.paper}>
                  {GetOfferTransactions(row).map( location => (
                    CreateLocationRow(location, approveTransaction)
                    ))}
                  </Paper>
                  {offerItem != null && offerItem.Status < 2 &&
                  <Button
                    onClick={() => markUserOfferAsComplete(GetUserOfferId(row), false)}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    
                  >
                    Mark As Completed
                  </Button>}
                  {offerItem != null && offerItem.Status < 2 &&
                  <Button
                    onClick={() => markUserOfferAsComplete(GetUserOfferId(row), true)}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Set To Completed (Award {
            (offerItem != null)?
            <NumericFormat displayType={'text'}  value={offerItem.Reward} 
                  thousandSeparator={true} prefix={''}
                  suffix={' pts'} />
            :'x pts'})
                  </Button>  }
                </Box>
            }
            {/* {GetTermsURL(row) != null &&
            <Box margin={5}>
              <Typography variant="h4" gutterBottom component="div">
                Terms URL
              </Typography>
              <Paper className={classes.paper}>
              {GetTermsURL(row)}
              
              </Paper>
            </Box>
            }
            {GetExtraURL(row) != null &&
            <Box margin={5}>
              <Typography variant="h4" gutterBottom component="div">
                Extra URL
              </Typography>
              <Paper className={classes.paper}>
              {GetExtraURL(row)}
              
              </Paper>
            </Box>
            } */}
            {/* {row.OfferLocations != null &&
            <Box margin={5}>
              <Typography variant="h4" gutterBottom component="div">
                Available Locations
              </Typography>
              <Paper className={classes.paper}>
              {row.OfferLocations.map( location => (
                CreateLocationRow(location)
                ))}
              
              </Paper>
            </Box>
            } */}
           
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
