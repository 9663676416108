import { TramRounded } from "@mui/icons-material";
import axios from "axios";
import SessionHelper from "helpers/SessionHelper";
import {EXPIRED_TOKEN} from "constants/constants"

class WebService {
  constructor() {
    if (!WebService.instance) {
      console.log("Inside the webService file: ");

      if (this.isInDevelopmentEnvironment()) {
        // dev
        this.baseUrl = process.env.REACT_APP_Local_Server_URL;
      } else {
        // Remote dev code:
        // this.baseUrl = process.env.SERVER_URL;
        
        // production code
        this.baseUrl = "https://app.betfully.com/";
      }

      this.bearerToken = this.getJwtToken();
      this.refreshToken = this.getRefreshToken();

      this.axiosInstance = axios.create({
        baseURL: this.baseUrl,
      });

      WebService.instance = this;
      WebService.instance.setAxiosHandler();
      console.log("initialized webservice");
    }

    return WebService.instance;
  }

  isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
      ? false
      : true;
  };

  setAxiosHandler() {
    // Used for intercepting requests for every call:
    console.log("inside interceptors");
    WebService.instance.axiosInstance.interceptors.request.use((request) =>
      WebService.instance.requestHandler(request)
    );
  }

  requestHandler = (request) => {
    console.log("inside requestHandler");
    if (WebService.instance.isHandlerEnabled(request)) {
      request.headers["Content-Type"] = "application/json";
      request.headers["Authorization"] =
        "Bearer " + WebService.instance.getJwtToken();
      console.log(WebService.instance.getJwtToken());
    }
    return request;
  };

  //
  WebServiceRequest(
    requestEndPoint,
    requestParam,
    webPath = true,
    enableHandler = true,
    retry = true
  ) {
    console.log("Server Call: " + requestEndPoint);

    if(requestParam.CompanyId === undefined){
      requestParam.CompanyId = SessionHelper.getCompanyId();
    }

    return WebService.instance.axiosInstance
      .post(requestEndPoint, requestParam, { handlerEnabled: enableHandler })

      .then((res) => {
        console.log("in res");
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          console.log("error in response");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
        console.log("error in responseA");

        if (error.response.headers["token-expired"]) {
          console.log("error in responseB");
          console.log("webpath: "+webPath)
          console.log("retry: "+retry)
          console.log("response status: "+error.response.status)
          if( webPath &&
            retry &&
            error.response.status == EXPIRED_TOKEN){
            }
          return EXPIRED_TOKEN;
        }
        console.log("error in responseD");
      });
  }

  // Sign in the user and retrieves the access token:
  SignIn(user) {
    return WebService.instance.axiosInstance
      .post(
        "User/SignIn",
        {
          UserName: user.UserName,
          Email: user.Email,
          Password: user.Password,
        },
        { handlerEnabled: false }
      )
      .then((res) => {
        this.saveJwtToken(res.data.JwtToken);
        this.saveRefreshToken(res.data.RefreshToken);
        this.saveUserAuthorization(res.data.UserRole);
        SessionHelper.setRole(res.data.UserRole);
        SessionHelper.setName(user.Email);
        return true;
      })
      .catch((error) => {
        console.log("There was an error in the Sign In call");
        console.log(error);
      });
  }

  // Refresh the jwt and access token for the user:
  RefreshToken() {
    return WebService.instance.axiosInstance
      .post("User/RefreshToken", {
        JwtToken: WebService.instance.getJwtToken(),
        RefreshToken: WebService.instance.getRefreshToken(),
      })
      .then((res) => {
        console.log("ResfreshToken Response");
        console.log("New Token:");
        console.log(res.data);
        this.saveJwtToken(res.data.JwtToken);
        this.saveRefreshToken(res.data.RefreshToken);

        return res.data;
      })
      .catch((error) => {
        console.log("There was an error in the Sign In call");
        console.log(error);
      });
  }
  
  async GetMyDashboard(history) {
    var requestEndPoint = "api/website/GetMyDashboard";
    var requestParam = {};

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetMyOffers(history) {
    var requestEndPoint = "api/website/GetMyOffers";
    var requestParam = {};

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    console.log(response);
    return response;
  }

  async GetMyOfferDetails(history, props) {
    var requestEndPoint = "api/website/GetMyOfferDetails";
    var requestParam = { IntTag: props.IntTag };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateOffer(history, OfferItem, OfferCriteriaList, storedImageId, LocationsList, ImgUrlInput, SelectedCompanyId) {
    var requestEndPoint = "api/website/CreateOffer";
    var requestParam = {
      Add: OfferItem.Add,
      IntTag2: storedImageId,
      StringTag: ImgUrlInput,
      OfferItemRequest: {
        Title: OfferItem.Title,
        Title2: OfferItem.Title2,
        Description: OfferItem.Description,
        OfferURL: OfferItem.OfferURL,
        TermsURL: OfferItem.TermsURL,
        TermsSummary: OfferItem.TermsSummary,
        ExtraURL: OfferItem.ExtraURL,
        CashbackPercent: OfferItem.CashbackPercent,
        StartDate: OfferItem.StartDate,
        EndDate: OfferItem.EndDate,
        AmountOfDaysValid: OfferItem.AmountOfDaysValid,
        MaxDeposit: OfferItem.MaxDeposit,
        MinDeposit: OfferItem.MinDeposit,
        MaxTransactionsAllowed: OfferItem.MaxTransactionsAllowed,
        OfferTransactionType: OfferItem.OfferTransactionType,
        OfferActionType: OfferItem.OfferActionType,
        OfferRewardType: OfferItem.OfferRewardType,
        SmallLogoId: OfferItem.SmallLogoId,
        BackgroundImageId: OfferItem.BackgroundImageId,
        RequirementList: [OfferItem.InstructionSet1, OfferItem.InstructionSet2, OfferItem.InstructionSet3],
        StoredImageId: OfferItem.StoredImageId
      },
      CriteriaList: OfferCriteriaList,
      Ids: LocationsList
    };

    if(SelectedCompanyId !== undefined){
      requestParam.CompanyId = SelectedCompanyId
    }

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateCompany(history, name, websiteUrl, imageUrl, storedImageId, companyCategory) {
    var requestEndPoint = "api/website/CreateCompany";
    var requestParam = {
      StringTag: name,
      StringTag2: websiteUrl,
      StringTag3: imageUrl,
      IntTag: storedImageId,
      IntTag3: companyCategory,
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async EditCompany(history, companyId, name, websiteUrl, imageUrl, storedImageId, companyCategory) {
    var requestEndPoint = "api/website/ModifyCompany";
    var requestParam = {
      IntTag: companyId,
      IntTag2: storedImageId,
      IntTag3: companyCategory,
      StringTag: name,
      StringTag2: websiteUrl,
      StringTag3: imageUrl,
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async DeleteCompany(history, companyId) {
    var requestEndPoint = "api/website/DeleteCompany";
    var requestParam = {
      IntTag: companyId
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateKeyword(history, keyword) {
    var requestEndPoint = "api/website/CreateKeyword";
    var requestParam = {
      StringTag: keyword,
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ModifyKeyword(history, keyword, keywordModelId) {
    var requestEndPoint = "api/website/ModifyKeyword";
    var requestParam = {
      StringTag: keyword,
      IntTag: keywordModelId,
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async RemoveKeyword(history, keywordModelId) {
    var requestEndPoint = "api/website/RemoveKeyword";
    var requestParam = {
      IntTag: keywordModelId,
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetMyKeywordDetails(history, props) {
    var requestEndPoint = "api/website/GetMyKeywordDetails";
    var requestParam = { IntTag: props.IntTag };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === true) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ProcessKeyword(history, props) {
    var requestEndPoint = "api/website/ProcessKeyword";
    var requestParam = {
      IntTag: props.IntTag,
      IntTag2: props.IntTag2,
      StringTag: props.StringTag,
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ModifyOffer(history, props, offerId, storedImageId, OfferCriteriaList, LocationsList, ImgUrlInput, SelectedCompanyId) {
    var requestEndPoint = "api/website/ModifyOffer";
    var requestParam = {
      IntTag: offerId,
      IntTag2: storedImageId,
      StringTag: ImgUrlInput,
      Add: props.Add,
      OfferItemRequest: {
        Title: props.Title,
        Title2: props.Title2,
        Description: props.Description,
        OfferURL: props.OfferURL,
        TermsURL: props.TermsURL,
        TermsSummary: props.TermsSummary,
        ExtraURL: props.ExtraURL,
        Reward: props.Reward,
        CashbackPercent: props.CashbackPercent,
        AmountOfDaysValid: props.AmountOfDaysValid,
        MaxDeposit: props.MaxDeposit,
        MinDeposit: props.MinDeposit,
        MaxTransactionsAllowed: props.MaxTransactionsAllowed,
        StartDate: props.StartDate,
        EndDate: props.EndDate,
        OfferRewardType: props.OfferRewardType,
        OfferActionType: props.OfferActionType,
        SmallLogoId: props.SmallLogoId,
        BackgroundImageId: props.BackgroundImageId,
        RequirementList: [props.InstructionSet1, props.InstructionSet2, props.InstructionSet3],
        StoredImageId: props.StoredImageId
      },
      CriteriaList: OfferCriteriaList,
      Ids: LocationsList
    };

    if(SelectedCompanyId !== undefined){
      requestParam.CompanyId = SelectedCompanyId
    }

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async RemoveOffer(history, props) {
    var requestEndPoint = "api/website/RemoveOffer";
    var requestParam = { IntTag: props.IntTag };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetUsers(history) {
    var requestEndPoint = "api/website/GetUsers";
    var requestParam = {};

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }
  
  async ApproveSingleOfferTransaction(history, betfullyUserId, offerTransactionId) {
    var requestEndPoint = "api/website/ApproveSingleOfferTransaction";
    var requestParam = { 
      IntTag: betfullyUserId, 
      IntTag2: offerTransactionId 
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetUserDetails(history, props) {
    var requestEndPoint = "api/website/GetUserDetails";
    var requestParam = { IntTag: props.BetfullyUserId };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ModifyUserStanding(history, firebaseId, updatedStatus, numDays, reasonString) {
    var requestEndPoint = "api/website/ModifyUserStanding";
    var requestParam = {
      IntTag: firebaseId,
      IntTag2: updatedStatus,
      IntTag3: numDays,
      StringTag: reasonString
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async UpdateUserLocation(history, firebaseId, provinceId) {
    var requestEndPoint = "api/website/UpdateUserLocation";
    var requestParam = {
      IntTag: firebaseId,
      IntTag2: provinceId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ModifyUserPayoutEmail(history, firebaseId, emailString) {
    var requestEndPoint = "api/website/ModifyUserPayoutEmail";
    var requestParam = {
      IntTag: firebaseId,
      StringTag: emailString
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  
  async AwardReclaimUserPoints(history, betfullyUserId, pointAmount, reasonString) {
    var requestEndPoint = "api/website/AwardReclaimUserPoints";
    var requestParam = {
      IntTag: betfullyUserId,
      IntTag2: pointAmount,
      StringTag: reasonString
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }
  
  async MarkUserOfferAsComplete(history, betfullyUserId, userOfferId, awardPoints) {
    var requestEndPoint = "api/website/MarkUserOfferAsComplete";
    var requestParam = {
      IntTag: betfullyUserId,
      IntTag2: userOfferId,
      BoolTag: awardPoints
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }
  async ForceUpdateSingleBankPlaidTransactions(history, betfullyUserId, bankId) {
    var requestEndPoint = "api/website/ForceUpdateSingleBankPlaidTransactions";
    var requestParam = {
      IntTag: betfullyUserId,
      IntTag2: bankId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async FireTestWebhook(history, betfullyUserId, bankId) {
    var requestEndPoint = "api/website/FireTestWebhook";
    var requestParam = {
      IntTag: betfullyUserId,
      IntTag2: bankId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ResetBankLogin(history, betfullyUserId, bankId) {
    var requestEndPoint = "api/website/ResetBankLogin";
    var requestParam = {
      IntTag: betfullyUserId,
      IntTag2: bankId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ForceUpdatePlaidTransactions(history, betfullyUserId) {
    var requestEndPoint = "api/website/ForceUpdatePlaidTransactions";
    var requestParam = {
      IntTag: betfullyUserId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetUserBankAccountTransactions(history, betfullyUserId, bankAccountId, page, numPerPage) {
    var requestEndPoint = "api/website/GetUserBankTransactions";
    var requestParam = {
      IntTag: betfullyUserId,
      IntTag2: bankAccountId,
      IntTag3: page,
      IntTag4: numPerPage
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetUmatchedTransactions(history, companyId) {
    var requestEndPoint = "api/website/GetUmatchedTransactions";
    var requestParam = {
      IntTag: companyId
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateAdminUser(history, props) {
    var requestEndpoint = "Administration/CreateUser";
    var requestParam = props;
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAdminRolesAndAdminCompanies(history, props) {
    var requestEndpoint = "Administration/GetAllRolesAndCompanies";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetUserLogs(history, props) {
    var requestEndPoint = "api/website/GetUserLogs";
    var requestParam = { IntTag: props.IntTag, StringTag: props.StringTag };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response == EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetFraudShieldLogs(history, userId) {
    var requestEndPoint = "api/website/GetFraudShieldLogs";
    var requestParam = { IntTag: userId, StringTag: "" };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response == EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetCustomFraudShieldLogs(history, eventType, valueInt, valueFloat, numDays, userId) {
    var requestEndPoint = "api/website/GetCustomFraudShieldLogs";
    var requestParam = { 
      IntTag: eventType, 
      IntTag2: valueInt,
      IntTag3: numDays,
      IntTag4: userId,
      FloatTag: valueFloat
     };
    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response == EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetUserPayouts(history) {
    var requestEndpoint = "api/website/GetPaypalPayoutRequests";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetPaypalWebhookResponse(history) {
    var requestEndpoint = "api/website/GetAllPaypalBatchRequestResponses";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetPaypalBatchPayoutDetails(history, paypalBatchPayoutId) {
    var requestEndpoint = "api/website/GetPaypalBatchPayoutDetails";
    var requestParam = {
      StringTag: paypalBatchPayoutId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CompletePaypalPayouts(history, payoutRequestIds) {
    var requestEndpoint = "api/website/CompletePaypalPayoutToUsers";
    var requestParam = {
      Ids: payoutRequestIds,
    };
    console.log("Request Param value");
    console.log(requestParam);
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async RejectUsersPaypalPayout(history, payoutRequestIds) {
    var requestEndpoint = "api/website/RejectUsersPaypalPayout";
    var requestParam = {
      Ids: payoutRequestIds,
    };
    console.log("Request Param value");
    console.log(requestParam);
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAdminUsers(history, props) {
    var requestEndpoint = "Administration/GetAllAdmins";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetMyInfo(history, companyIdOverride) {
    var requestEndpoint = "api/website/GetAdminDetails";
    var requestParam = {
      IntTag: companyIdOverride
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetMyEvents(history, props) {
    var requestEndpoint = "api/website/GetMyEvents";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAllKeywords(history, props) {
    var requestEndpoint = "api/website/GetAllKeywords";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  
  async CreateDemoData(history) {
    var requestEndpoint = "api/website/CreateDemoData";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }
  
  async ExportAdminOffers(history) {
    var requestEndpoint = "api/website/ExportAdminOffers";
    var requestParam = {
      // IntTag: firebaseId
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'OfferReport.csv'); //or any other extension
    document.body.appendChild(link);
    link.click();

    return response;
  }
  
  async MyExportAction(history, firebaseId, betfullyId) {
    var requestEndpoint = "api/website/MyExportAction";
    var requestParam = {
      IntTag: firebaseId
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transactions-'+betfullyId+'.csv'); //or any other extension
    document.body.appendChild(link);
    link.click();

    return response;
  }

  
  async ResumeServer(history) {
    var requestEndpoint = "api/website/ResumeServer";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CheckSaveAndClearBothCachesComplete(history) {
    var requestEndpoint = "api/website/CheckSaveAndClearBothCachesComplete";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async SaveAndClearBothCaches(history) {
    var requestEndpoint = "api/website/SaveAndClearBothCaches";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAllBanners(history, props) {
    var requestEndpoint = "api/website/GetBanners";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateBanner(history, Order, OfferId, IsActive, ImageUrl, ImageId, DeepLink, ExternalLink) {
    console.log("Image url: " + ImageUrl);
    console.log("Image Id: " + ImageId);
    var requestEndpoint = "api/website/CreateBanner";
    var requestParam = {
      IntTag: Order,
      IntTag2: OfferId,
      BoolTag: IsActive,
      StringTag: ImageUrl,
      IntTag4: ImageId,
      IntTag3: DeepLink,
      StringTag2: ExternalLink
    };
    console.log("Create Banner Param: " + requestParam.StringTag);
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async DeleteBanner(history, BannerId) {
    var requestEndpoint = "api/website/DeleteBanner";
    var requestParam = {
      IntTag: BannerId
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }
  
  async DeleteUserPersonalInfoFromBetfully(history, UserId) {
    var requestEndpoint = "api/website/DeleteUserPersonalInfoFromBetfully";
    var requestParam = {
      IntTag: UserId
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async RetrieveUserSummary(history, UserId) {
    var requestEndpoint = "api/website/RetrieveUserSummary";
    var requestParam = {
      IntTag: UserId
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async UnlinkUserAccountFromFirebase(history, UserId) {
    var requestEndpoint = "api/website/UnlinkFirebaseFromBetfullyAccount";
    var requestParam = {
      IntTag: UserId
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ModifyBanner(history, BannerId, Order, OfferId, IsActive, ImageUrl, ImageId, DeepLink, ExternalLink) {
    var requestEndpoint = "api/website/ModifyBanner";
    var requestParam = {
      IntTag3: BannerId,
      IntTag: Order,
      IntTag2: OfferId,
      BoolTag: IsActive,
      StringTag: ImageUrl,
      IntTag4: ImageId,
      IntTag5: DeepLink,
      StringTag2: ExternalLink
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAllStoredImages(history, CompanyId, onlyCompanyId, ImageType) {
    var requestEndpoint = "api/website/GetStoredImages";
    var requestParam = {
      IntTag: CompanyId,
      BoolTag3: onlyCompanyId,
      IntTag4: ImageType,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateStoredImage(
    history,
    ImageUrl,
    ImageName,
    ImageType,
    CompanyId,
    IsDefault,
    onlyCompanyId
  ) {
    var requestEndpoint = "api/website/CreateStoredImage";
    var requestParam = {
      IntTag: CompanyId,
      IntTag2: ImageType,
      BoolTag: true,
      StringTag: ImageUrl,
      StringTag2: ImageName,
      BoolTag2: IsDefault,
      BoolTag3: onlyCompanyId,
    };
    console.log("Create StoredImage Param: " + requestParam.StringTag);
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }

    return response;
  }

  async ModifyStoredImage(
    history,
    StoredImageId,
    IsActive,
    IsDefault,
    onlyCompanyId,
    companyId
  ) {
    var requestEndpoint = "api/website/ModifyStoredImage";
    var requestParam = {
      IntTag: companyId,
      IntTag3: StoredImageId,
      BoolTag: IsActive,
      BoolTag2: IsDefault,
      BoolTag3: onlyCompanyId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAllCompanies(history, props) {
    var requestEndpoint = "api/website/GetCompanies";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }
  
  async GetAllDomains(history, props) {
    var requestEndpoint = "api/website/GetDomains";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAllCommissions(history, props) {
    var requestEndpoint = "api/website/GetWildfireCommissions";
    var requestParam = {};
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async UpdateAdminUser(history, props) {
    var requestEndpoint = "Administration/UpdateAdmin";
    var requestParam = {
      AdminUserId: props.AdminUserId,
      Username: props.UserName,
      Email: props.Email,
      userRoleId: props.UserRoleId,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async DeleteAdminUser(history, props) {
    var requestEndpoint = "Administration/DeleteAdmin";
    var requestParam = {
      AdminUserId: props.AdminUserId,
      Username: props.UserName,
      Email: props.Email,
      userRoleId: props.UserRoleId,
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ConfirmAccount(history, props) {
    var requestEndpoint = "User/ConfirmAccount";
    var requestParam = props;
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ResetPasswordRequest(history, props) {
    var requestEndpoint = "User/RequestPasswordReset";
    var requestParam = props;
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === true) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async ResetPassword(history, props) {
    var requestEndpoint = "User/ResetPassword";
    var requestParam = props;
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === true) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async UpdateOfferStatus(history, offerId, newStatus) {
    var requestEndPoint = "api/website/UpdateOfferStatus";
    var requestParam = { IntTag: offerId, IntTag2: newStatus };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetActiveAndPendingOffers(history) {
    var requestEndPoint = "api/website/GetActiveAndPendingOffers";
    var requestParam = {};

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateOfferChangeRequest(history, OfferItem, OfferId, OfferCriteriaList, storedImageId, LocationsList, ImgUrlInput, SelectedCompanyId) {
    var requestEndPoint = "api/website/CreateOfferChangeRequest";
    var requestParam = {
      Add: OfferItem.Add,
      IntTag: OfferId,
      IntTag2: storedImageId,
      StringTag: ImgUrlInput,
      OfferItemRequest: {
        Title: OfferItem.Title,
        Title2: OfferItem.Title2,
        Description: OfferItem.Description,
        OfferURL: OfferItem.OfferURL,
        TermsURL: OfferItem.TermsURL,
        TermsSummary: OfferItem.TermsSummary,
        ExtraURL: OfferItem.ExtraURL,
        CashbackPercent: OfferItem.CashbackPercent,
        StartDate: OfferItem.StartDate,
        EndDate: OfferItem.EndDate,
        AmountOfDaysValid: OfferItem.AmountOfDaysValid,
        MaxDeposit: OfferItem.MaxDeposit,
        MaxTransactionsAllowed: OfferItem.MaxTransactionsAllowed,
        OfferTransactionType: OfferItem.OfferTransactionType,
      },
      CriteriaList: OfferCriteriaList,
      Ids: LocationsList
    };

    if(SelectedCompanyId !== undefined){
      requestParam.CompanyId = SelectedCompanyId
    }

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async RemoveOfferChangeRequest(history, requestId) {
    var requestEndPoint = "api/website/RemoveOfferChangeRequest";
    var requestParam = { IntTag: requestId };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async RejectOfferChangeRequest(history, requestId) {
    var requestEndPoint = "api/website/RejectOfferChangeRequest";
    var requestParam = { IntTag: requestId };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async AcceptOfferChangeRequest(history, requestId) {
    var requestEndPoint = "api/website/AcceptOfferChangeRequest";
    var requestParam = { IntTag: requestId };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async WildfireItemOverride(history, overrideItem) {
    var requestEndPoint = "api/website/WildfireItemOverride";
    var requestParam = {
      CompanyId: overrideItem.editMerchantId,
      IntTag: overrideItem.editImageId,
      IntTag2: overrideItem.editCouponId,
      BoolTag: overrideItem.editBool1,
      BoolTag2: overrideItem.editBool2,
      BoolTag3: overrideItem.editBool3,
      StringTag: overrideItem.editString1,
      StringTag2: overrideItem.editString2,
      StringTag3: overrideItem.editString3,
   
    };

    let response = await WebService.instance.WebServiceRequest(
      requestEndPoint,
      requestParam
    );
    if (response === EXPIRED_TOKEN) {
      let abc = await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndPoint,
        requestParam
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async CreateAppSettingItem(history, itemKey, itemValue) {
    var requestEndpoint = "api/website/CreateAppSettingItem";
    var requestParam = {
      StringTag: itemKey,
      StringTag2: itemValue
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  

  async UpdateAppSettingItem(history, settingId, itemValue) {
    var requestEndpoint = "api/website/UpdateAppSettingItem";
    var requestParam = {
      IntTag: settingId,
      StringTag: itemValue,
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  async GetAppSettingItems(history) {
    var requestEndpoint = "api/website/GetAppSettingItems";
    var requestParam = {
    };
    let response = await WebService.instance.WebServiceRequest(
      requestEndpoint,
      requestParam,
      false
    );

    if (response === EXPIRED_TOKEN) {
      await WebService.instance.RefreshToken();
      response = await WebService.instance.WebServiceRequest(
        requestEndpoint,
        requestParam,
        false
      );
      if(response === EXPIRED_TOKEN){
        history.push({pathname: "./sign-in"});
      }
    }
    return response;
  }

  NeedToTryToRefreshToken = (statusCode) => {
    console.log("Inside needToRefreshToken private var");
    return statusCode === EXPIRED_TOKEN;
  };

  getJwtToken() {
    return localStorage.getItem("token");
  }

  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  saveJwtToken(token) {
    localStorage.setItem("token", token);
  }

  saveRefreshToken(refreshToken) {
    localStorage.setItem("refreshToken", refreshToken);
  }

  saveUserAuthorization(authorization) {
    sessionStorage.setItem("auth", authorization);
  }

  getUserAuthorization() {
    return sessionStorage.getItem("auth");
  }

  isInDevelopmentEnvironment() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  }
}

const instance = new WebService();
export default instance;
