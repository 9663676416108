import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import CompaniesTable from './Components/CompaniesTable'
import CompaniesToolbar from './Components/CompaniesToolbar'
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import { storage } from "../../firebase";
import ImagePreviewGrid from '../StoredImageList/Components/ImagePreviewGrid'
import { EXPIRED_TOKEN } from 'constants/constants'
import { 
    GetCompanyId,
    GetCompanyName,
    GetURL, 
    GetDateCreated,
    GetImageURL,
    GetCompanyStoredImageId
  } from '../../helpers/CompanyHelper'
import SessionHelper from "helpers/SessionHelper";
import Modal from 'react-bootstrap/Modal';
import Spinner from "react-bootstrap/Spinner";
import Form from 'react-bootstrap/Form';
import {
    Button
  } from "reactstrap";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    imageContainer: {
        height: 100,
        width: 300,
        margin: "0 auto",
        marginBottom: "20px",
        borderRadius: "1px",
        overflow: "hidden",
        display: "flex",
        alignItems: "normal",
        justifyContent: "center",
      },
      image: {
        width: "100%",
        objectFit : "contain"
      }
}));

const CompanyList = props => {
    const { history, ...rest } = props;
    const classes = useStyles();
    const [companies, setCompanies] = useState([]);

    const [closeEditCompany, setCloseEditCompany] = useState(false)
    const [showEditCompany, setShowEditCompany] = useState(false);
    const handleCancelEditCompany = () => setShowEditCompany(false);
    const handleCloseEditCompany = () => {setCloseEditCompany(!closeEditCompany); setShowEditCompany(false);}
    const handleShowEditCompany = (theCompany) => {
        console.log("The Company: " + theCompany.Name);
        setCompanyIdInput(GetCompanyId(theCompany));
        setCompanyStoredImageId(GetCompanyStoredImageId(theCompany))
        setCompanyInput(GetCompanyName(theCompany));
        setCompanyUrlInput(GetURL(theCompany));
        setCompanyImgInput(GetImageURL(theCompany));
        setShowEditCompany(true);
    }

    const [showViewStoredImages, setShowViewStoredImages] = useState(false);
    const handleCloseViewStoredImages = () => setShowViewStoredImages(false);
    const handleShowViewStoredImages = () => {
        setShowViewStoredImages(true);
    }
    const onImageSelected = (imageId, imageUrl) => {
        console.log("Image selected Id: "+ imageId)
        setCompanyImgInput(imageUrl);
        setCompanyStoredImageId(imageId);
        handleCloseViewStoredImages();
    }

    const [CompanyInput, setCompanyInput] = useState("");
    const [CompanyIdInput, setCompanyIdInput] = useState(0);
    const [CompanyUrlInput, setCompanyUrlInput] = useState("");
    const [CompanyImgInput, setCompanyImgInput] = useState("");
    const [CompanyStoredImageId, setCompanyStoredImageId] = useState(0);
    const [CompanyCategory, setCompanyCategory] = useState(5)
    const [isLoading, setIsLoading] = useState(false);
    const [picturesInput, setPictures] = useState([]);

    const onDrop = (picture, something) => {
        console.log("Image: "+picture);
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = something;
        //Validate the File Height and Width.
        image.onload = function () {
            var height = this.height;
            var width = this.width;



            if (height/width > 1.1 || height/width < 0.9) {
            alert("Recommended Ratio for images is 1:1");
            return false;
            }
            return true;
        };

        setPictures(
            picturesInput.concat(picture)
        );
    }

    const [showAddCompany, setShowAddCompany] = useState(false);
    const handleCloseAddCompany = () => setShowAddCompany(false);
    const handleShowAddCompany = () => {
        setShowAddCompany(true);
        setCompanyInput("");
        setCompanyIdInput(0);
        setCompanyStoredImageId(0);
        setCompanyUrlInput("");
        setCompanyImgInput("");
    }

    const handleCreateCompany = () => {
        console.log("Send Company")
        console.log("Company request:");
        console.log(CompanyInput);
        if(CompanyInput != null) {
            setIsLoading(true);
            WebService.CreateCompany(history, CompanyInput, CompanyUrlInput, null, CompanyStoredImageId, CompanyCategory)
                .then(data => {
                  console.log("ABC DONE");
                  console.log("Data: "+data);
                  if(data != null) {
                    SessionHelper.setCompanyList(data);
                    handleCloseAddCompany();
                    window.location.reload();
                  }else{
                    handleCloseAddCompany();
                    setIsLoading(false);
                  }
                });
        }
    }

    const handleEditCompany = () => {
        console.log("Edit Company")
        console.log(CompanyInput);
        if(CompanyInput != null) {
          WebService.EditCompany(history, CompanyIdInput, CompanyInput, CompanyUrlInput, 
                        CompanyImgInput, CompanyStoredImageId, CompanyCategory)
            .then(data => {
              console.log("ABC DONE");
              console.log("Data: "+data);
              if(data != null) {
                SessionHelper.setCompanyList(data);
                handleCloseEditCompany();
              }else{
                setShowEditCompany(false)
              }
            });
        }
    }
    
    const handleDeleteCompany = () => {
        if(CompanyIdInput  > 0) {
            setIsLoading(true);
            WebService.DeleteCompany(history, CompanyIdInput)
                .then(data => {
                  if(data != null) {
                    SessionHelper.setCompanyList(data);
                    handleCloseEditCompany();
                    window.location.reload();
                  }else{
                    handleCloseEditCompany();
                    setIsLoading(false);
                  }
            });
        }
    }

    const myChangeHandler = (event) => {
        console.log("Name:"+event.target.name);
        console.log("Value:"+event.target.value);
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'companyNameInput'){
            setCompanyInput(val);
        }else if(nam === 'companyUrlInput'){
            setCompanyUrlInput(val);
        }else if(nam === 'companyImgInput'){
            setCompanyImgInput(val);
        }else if(nam === 'companyCategory'){
            setCompanyCategory(val);
        }
        
    }

    useEffect(() => {
        console.log("using use effect");
            const fetchData = async () => {
                const result = await WebService.GetAllCompanies(history, undefined);
                if(result != null && result == EXPIRED_TOKEN){
                    history.push({pathname: "./sign-in"});
                }
                if (result != null) {
                    // Temporary sorting solution:
                    result.sort((firstCompany, secondCompany) =>
                    GetCompanyName(firstCompany).localeCompare(GetCompanyName(secondCompany)));
                    setCompanies(result)
                } else {
                    history.push({pathname: "./sign-in"});
                }
            };
            fetchData();
    }, [closeEditCompany]);

    return (
      <div className={classes.root}>
          {(isLoading) && <Spinner animation="border" variant="primary" />}

          {(!isLoading) &&
              <div>
    
                  <ImagePreviewGrid
                      showViewStoredImages={showViewStoredImages}
                      handleCloseViewStoredImages={handleCloseViewStoredImages}
                      onImageSelected={onImageSelected}
                      companyId={CompanyIdInput}
                      imageType={1}/>
                  <Modal
                      className={classes.content}
                      show={showAddCompany}
                      onHide={handleCloseAddCompany}
                      centered>
                      <Modal.Header closeButton>
                          <Modal.Title>{"Add Company"}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <Form>
                              <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Input the Company you would like us to scan for</Form.Label>
                                  <Form.Control required placeholder="Enter Company" name='companyNameInput'
                                                onChange={myChangeHandler}/>
                                  <Form.Label>Website Url</Form.Label>
                                  <Form.Control required placeholder="Enter Company Website" name='companyUrlInput'
                                                onChange={myChangeHandler}/>
                                  <select id="companyCategory" name="companyCategory" value={CompanyCategory}
                                          onChange={myChangeHandler}>
                                      <option value="0">Online Casino</option>
                                      <option value="1">Sportsbooks</option>
                                      <option value="2">Fantasy Sports</option>
                                      <option value="3">Horse Racing</option>
                                      <option value="4">Food and Restaurants</option>
                                      <option value="5">Free Money</option>
                                  </select>
                                  {/* <Form.Label>Logo Url</Form.Label>
                            <Form.Control required placeholder="Enter Company Image Url" name='companyImgInput' onChange={myChangeHandler}/> */}
                                  <Form.Text className="text-muted">
    
                                  </Form.Text>
                              </Form.Group>
                          </Form>
    
                      </Modal.Body>
                      <Modal.Footer>
                          <Button variant="primary" onClick={handleCreateCompany}>
                              Yes
                          </Button>
                          <Button variant="secondary" onClick={handleCloseAddCompany}>
                              Cancel
                          </Button>
                      </Modal.Footer>
                  </Modal>
                  <Modal
                      className={classes.content}
                      show={showEditCompany}
                      onHide={handleCloseEditCompany}
                      centered>
                      <Modal.Header closeButton>
                          <Modal.Title>{"Edit Company"}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          <div>{"Click Image To Edit"}</div>
                          <div className={classes.imageContainer}>
                              <img
                                  alt="Product"
                                  className={classes.image}
                                  onClick={() => handleShowViewStoredImages()}
                                  src={CompanyImgInput}
                              />
                          </div>
                          <Form>
                              <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Input the Company you would like us to scan for</Form.Label>
                                  <Form.Control required placeholder="Enter Company" name='companyNameInput'
                                                value={CompanyInput} onChange={myChangeHandler}/>
                                  <Form.Label>Website Url</Form.Label>
                                  <Form.Control required placeholder="Enter Company Website" name='companyUrlInput'
                                                value={CompanyUrlInput} onChange={myChangeHandler}/>
                                  <select id="companyCategory" name="companyCategory" value={CompanyCategory}
                                          onChange={myChangeHandler}>
                                      <option value="0">Online Casino</option>
                                      <option value="1">Sportsbooks</option>
                                      <option value="2">Fantasy Sports</option>
                                      <option value="3">Horse Racing</option>
                                      <option value="4">Food and Restaurants</option>
                                      <option value="5">Free Money</option>
                                  </select>
                                  {/* <Form.Label>Logo Url</Form.Label>
                            <Form.Control required placeholder="Enter Company Image Url" name='companyImgInput' value={CompanyImgInput} onChange={myChangeHandler}/>
                            <Form.Text className="text-muted">
                            
                            </Form.Text> */}
                              </Form.Group>
                          </Form>
                      </Modal.Body>
                      <Modal.Footer>
                          {/* <Button variant="primary" onClick={handleEditCompany}>
                        Delete
                    </Button> */}
                        <Button variant="danger" onClick={handleDeleteCompany}>
                              DELETE
                          </Button>
                          <Button variant="primary" onClick={handleEditCompany}>
                              Save Changes
                          </Button>
                          <Button variant="secondary" onClick={handleCancelEditCompany}>
                              Cancel
                          </Button>
                      </Modal.Footer>
                  </Modal>
                  <CompaniesToolbar history={history} openAddCompanyPopup={handleShowAddCompany}/>
                  <div className={classes.content}>
                      <CompaniesTable Companies={companies} openEditCompanyPopup={handleShowEditCompany}/>
                  </div>
              </div>
          }
      </div> 
    );
};

CompanyList.propTypes = {
    history: PropTypes.object
};

export default withMasterAuthorization(CompanyList);
