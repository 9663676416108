import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner'
import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import moment from "moment";

import { SearchInput } from "components";
import DatePicker from "react-datepicker";
import WebService from "../../../../api/WebServices";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { IconButton, Button, Tooltip, TableSortLabel } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DateRangeIcon from '@mui/icons-material/DateRange';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";

import { Form, Row, Col } from "react-bootstrap";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  Unknown_INT,
  SignIn_INT,
  ActivatedOffer_INT,
  LinkedBankAccount_INT,
  ProcessedTransaction_INT,
  CashOutRequest_INT,
  AdProcessed_INT,
  TransactionAppliedToOffer_INT,
  FS_OfferMaxedOut_INT,
  FS_PointsEarnedExceededThreshold_INT,
  FS_DuplicateBankAccountLinked_INT,
  FS_DuplicateCreditCardLinked_INT,
  FS_WithdrawDepositDifference_INT,
  FS_BankAccountCountThresholdExceeded_INT,
  FS_LoggedInOutsideUS_INT,
  GetLogTypeName,
  GetDateString,
  GetEventName,
  GetDescription,
  GetType,
  GetId,
  GetDate
} from "helpers/LogHelper";
import { GetUserId } from "helpers/UserHelper";
import withAuthorization from "../../../../helpers/Authorization";
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  row: {
    height: "62px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlWidth: {
    width: "162px",
    marginRight: theme.spacing(2)
  },
  customLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100"
  }
}));

const UserActivityView = (props) => {
  const { className, history, user, showFraudShieldOnly, staticContext, ...rest } = props;

  const classes = useStyles();

  const TIME_RANGE = {
    DEFAULT: {Name:'NOTHING', Val:-1},
    SAME_DAY: {Name:'Last Day', Val:0},
    MONTH: {Name:'Last Month', Val:1},
    MONTH_6: {Name:'Last 6 Months', Val:2},
    YEAR: {Name:'Last Year', Val:3},
    ALL_TIME: {Name:'All Time', Val:4},
    CUSTOM: {Name:'Custom', Val:5}
  }

  const criteriaList = [
    SignIn_INT,
    ActivatedOffer_INT,
    LinkedBankAccount_INT,
    ProcessedTransaction_INT,
    CashOutRequest_INT,
    AdProcessed_INT,
    TransactionAppliedToOffer_INT,
    FS_OfferMaxedOut_INT, 
    FS_PointsEarnedExceededThreshold_INT, 
    FS_DuplicateBankAccountLinked_INT,
    FS_WithdrawDepositDifference_INT,
    FS_BankAccountCountThresholdExceeded_INT,
    FS_LoggedInOutsideUS_INT,
    FS_DuplicateCreditCardLinked_INT
  ];

  const [isLoading, setIsLoading] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [selectedLogFilter, setSelectedLogFilter] = useState(-1);
  const [userId, setUserId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState(TIME_RANGE.ALL_TIME.Val);

  const dateSelected = (date) => {
    setStartDate(date);
    //setDateRange(TIME_RANGE.CUSTOM.Val);
  };

  const endDateSelected = (date) => {
    setEndDate(date);
    //setDateRange(TIME_RANGE.CUSTOM.Val);
  };

  const rangeSelected = (range) => {
    if(range == TIME_RANGE.CUSTOM.Val || dateRange == TIME_RANGE.CUSTOM.Val){
      setStartDate(new Date());
      setEndDate(new Date());
    }
    setDateRange(range);
  };

  

  const COLUMNS_SORT = {
    DEFAULT: -1,
    TIME_DESC: 0,
    TIME_ASC: 1,
    ACTIVITY_DESC: 2,
    ACTIVITY_ASC: 3,
    MESSAGE_DESC: 4,
    MESSAGE_ASC: 5,
    STATUS_DESC: 6,
    STATUS_ASC: 7
  }

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  
  const toggleSort = (columnType) => {
    var nextColumn = columnType;
    switch(columnType){
      case COLUMNS_SORT.MESSAGE_ASC:
      case COLUMNS_SORT.MESSAGE_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.MESSAGE_DESC)?COLUMNS_SORT.MESSAGE_ASC:COLUMNS_SORT.MESSAGE_DESC;
        sortLogs(nextColumn);
        setSelectedSort(nextColumn);
        break;
     
      case COLUMNS_SORT.STATUS_ASC:
      case COLUMNS_SORT.STATUS_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.STATUS_DESC)?COLUMNS_SORT.STATUS_ASC:COLUMNS_SORT.STATUS_DESC;
        sortLogs(nextColumn);
        setSelectedSort(nextColumn);
        break;
      case COLUMNS_SORT.TIME_ASC:
      case COLUMNS_SORT.TIME_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.TIME_DESC)?COLUMNS_SORT.TIME_ASC:COLUMNS_SORT.TIME_DESC;
        sortLogs(nextColumn);
        setSelectedSort(nextColumn);
        break;
      case COLUMNS_SORT.ACTIVITY_ASC:
      case COLUMNS_SORT.ACTIVITY_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.ACTIVITY_DESC)?COLUMNS_SORT.ACTIVITY_ASC:COLUMNS_SORT.ACTIVITY_DESC;
        sortLogs(nextColumn);
        setSelectedSort(nextColumn);
        break;
      default:
        nextColumn = COLUMNS_SORT.DEFAULT;
        sortLogs(nextColumn);
        setSelectedSort(COLUMNS_SORT.DEFAULT);
        break;
    }
   
  };

  const sortLogs = (columnType) =>{
    logs.sort(function(userA, userB) {
      var compareResult = 0;
      switch(columnType){
        case COLUMNS_SORT.ACTIVITY_ASC:
          if (GetType(userA)<GetType(userB)){
            compareResult = -1;
          }
          if (GetType(userA)>GetType(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.ACTIVITY_DESC:
          if (GetType(userA)>GetType(userB)){
            compareResult = -1;
          }
          if (GetType(userA)<GetType(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.MESSAGE_ASC:
          compareResult = GetDescription(userB).localeCompare(GetDescription(userA));
          break;
        case COLUMNS_SORT.MESSAGE_DESC:
          compareResult = GetDescription(userA).localeCompare(GetDescription(userB));
          break;
        case COLUMNS_SORT.STATUS_ASC:
          if (GetId(userA)<GetId(userB)){
            compareResult = -1;
          }
          if (GetId(userA)>GetId(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.STATUS_DESC:
          if (GetId(userA)>GetId(userB)){
            compareResult = -1;
          }
          if (GetId(userA)<GetId(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.TIME_ASC:
          compareResult = new Date(GetDate(userA)) - new Date(GetDate(userB));
          break;
        case COLUMNS_SORT.TIME_DESC:
          compareResult = new Date(GetDate(userB)) - new Date(GetDate(userA))
          break;
        
        default:
          compareResult = GetDate(userB).localeCompare(GetDate(userA));
      }
      //Compare unique ids in case of ties
      if(compareResult == 0){
        if (GetId(userA)<GetId(userB)){
          compareResult = -1;
        }
        if (GetId(userA)>GetId(userB)) {
          compareResult = 1;
        }
      }
      return compareResult;
    });
    applyAllFilters();
  };
  
  const isSortActive = (columnType) => {
    return selectedSort == columnType;
  };

  
  var RequestObject = {
    IntTag: GetUserId(user),
    StringTag: "",
  };

  const handleChange = (event) => {
    event.persist();

    setUserId(userId + event.nativeEvent.data);
  };

  useEffect(() => {
    if(!showFraudShieldOnly){
      const fetchData = async () => {
        const result = await WebService.GetUserLogs(history, RequestObject);
        setIsLoading(false)
        if (result != null) {
          const sortedResult = result.sort(function (b, a) {
            var nameA = a.DateCreated; // ignore upper and lowercase
            var nameB = b.DateCreated; // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          setLogs(sortedResult);
        } else {
          history.push({ pathname: "./sign-in" });
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if(showFraudShieldOnly){
      const fetchData = async () => {
        const result = await WebService.GetFraudShieldLogs(history, GetUserId(user));
        setIsLoading(false)
        if (result != null) {
          console.log(result);
          result.sort(
            (firstLog, secondLog) =>
                (firstLog.Id !== null
                  ? firstLog.Id
                  : 0)
              -
              
                  (secondLog.Id !== null
                      ? secondLog.Id
                      : 0)
          );
          setLogs(result);
        } else {
          history.push({ pathname: "./sign-in" });
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    console.log("Use effect called")
    applyAllFilters();
    setPage(0)
  },[logs, selectedLogFilter, dateRange,startDate,endDate]); //[selectedLogFilter, logs, startDate, endDate, dateRange]);

  const handleFilterSelected = (value) => {
      setSelectedLogFilter(value)
  };

 

  const applyAllFilters = () => {
    var allLogsVar = logs;
    if(selectedLogFilter != -1){
      allLogsVar = allLogsVar.filter(aLog => (GetType(aLog)) == selectedLogFilter)
    }else{
      
    }
   

   
    //0 is showing all times
    if(dateRange != TIME_RANGE.ALL_TIME.Val){
      var startFormatted = moment(startDate);
      var endFormatted = moment(startDate).add(-6, 'days');
      var compareFunc = (aDate) => {
        return true
      }
      console.log("Date Range Selected: "+dateRange)
      console.log("Date Range VAL: "+TIME_RANGE.MONTH.Val)
      console.log(TIME_RANGE.MONTH.Val == dateRange)
      if(dateRange == TIME_RANGE.SAME_DAY.Val){
        console.log("Date Range Option: "+TIME_RANGE.SAME_DAY.Name)
        compareFunc = (aDate) => {
          return moment(aDate).isSame(startDate, 'day')
        }
      }else if(dateRange == TIME_RANGE.MONTH.Val){
        console.log("Date Range Option: "+TIME_RANGE.MONTH.Name)
        endFormatted = moment(startDate).add(-1, 'months');
        compareFunc = (aDate) => {
          return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
        }
      }else if(dateRange == TIME_RANGE.MONTH_6.Val){
        console.log("Date Range Option: "+TIME_RANGE.MONTH_6.Name)
        endFormatted = moment(startDate).add(-6, 'months');
        compareFunc = (aDate) => {
          return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
        }
      }else if(dateRange == TIME_RANGE.YEAR.Val){
        console.log("Date Range Option: "+TIME_RANGE.YEAR.Name)
        endFormatted = moment(startDate).add(-1, 'years');
        compareFunc = (aDate) => {
          return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
        }
      }else if(dateRange == TIME_RANGE.ALL_TIME.Val){
        console.log("Date Range Option: "+TIME_RANGE.ALL_TIME.Name)
      }else if(dateRange == TIME_RANGE.CUSTOM.Val){
        console.log("Date Range Option: "+TIME_RANGE.CUSTOM.Name)
        compareFunc = (aDate) => {
          endFormatted = moment(endDate);
          if(moment(endDate).isBefore(moment(startDate))){
            return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
          }else{
            return moment(aDate).isBetween(startFormatted, endFormatted, 'days', [])
          }
        }
      }
        

      console.log("Start Date: "+startFormatted);
      console.log("End Date: "+endFormatted);
      
      allLogsVar = allLogsVar.filter(
        aLog => compareFunc(GetDateString(aLog))
        // (dateRange == 1)?
        // moment((GetDate(aLog))).isSame(startDate, 'day'):
        // moment(GetDate(aLog)).isBetween(endFormatted, startFormatted, 'days', [])
      )
       
    }

    setFilteredLogs(allLogsVar)
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div>
      {!showFraudShieldOnly &&
      <div className={classes.row}>
      <Form inline>
          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            Activity Types
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            onChange = {(event) => handleFilterSelected(event.target.value)}
          >
            <option value={-1}>All</option>
            {criteriaList.map((aNum) => (
              <option value={aNum} key={aNum}>{GetLogTypeName(aNum)}</option>
            ))}
          </Form.Control>
        </Form>
        

        <DateRangeIcon className="mr-sm-2"/>
        <Form.Control
            as="select"
            className={classes.formControlWidth}
            id="inlineFormCustomSelectPref"
            onChange = {(event) => rangeSelected(event.target.value)}
          >
            <option value={TIME_RANGE.ALL_TIME.Val} key={TIME_RANGE.ALL_TIME.Val}>{TIME_RANGE.ALL_TIME.Name}</option>
            <option value={TIME_RANGE.MONTH_6.Val} key={TIME_RANGE.MONTH_6.Val}>{TIME_RANGE.MONTH_6.Name}</option>
            <option value={TIME_RANGE.MONTH.Val} key={TIME_RANGE.MONTH.Val}>{TIME_RANGE.MONTH.Name}</option>
            <option value={TIME_RANGE.YEAR.Val} key={TIME_RANGE.YEAR.Val}>{TIME_RANGE.YEAR.Name}</option>
            <option value={TIME_RANGE.CUSTOM.Val} key={TIME_RANGE.CUSTOM.Val}>{TIME_RANGE.CUSTOM.Name}</option>
        </Form.Control>
        {dateRange == TIME_RANGE.CUSTOM.Val && <DatePicker selected={startDate} onChange={date => dateSelected(date)}/>}
        {dateRange == TIME_RANGE.CUSTOM.Val && <FormLabel className="mr-sm-2 ml-sm-2"> to </FormLabel>}
        {dateRange == TIME_RANGE.CUSTOM.Val && <DatePicker selected={endDate} onChange={date => endDateSelected(date)}/>}
      </div>
}
      
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.TIME_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.TIME_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.TIME_ASC) || isSortActive(COLUMNS_SORT.TIME_DESC)}>
                        Time Stamp
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.ACTIVITY_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.ACTIVITY_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.ACTIVITY_ASC) || isSortActive(COLUMNS_SORT.ACTIVITY_DESC)}>
                        Activity Type
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.MESSAGE_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.MESSAGE_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.MESSAGE_ASC) || isSortActive(COLUMNS_SORT.MESSAGE_DESC)}>
                        Message
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { isLoading && <TableRow
                        className={classes.tableRow}
                        hover
                        key={GetId(-1)}
                      >
                        <TableCell></TableCell>

                    <Row>
                      <Col ></Col>
                      <Col ></Col>
                      <Col md="auto"><Spinner animation="border" variant="primary" /></Col>
                    </Row>
                    <TableCell></TableCell>
                  </TableRow>}
                  { !isLoading && (filteredLogs == null || filteredLogs.length == 0) && <TableRow
                        className={classes.tableRow}
                        hover
                        key={GetId(-1)}
                      >
                    <TableCell></TableCell>
                    <TableCell>{"No Data Available"}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>}
                  {filteredLogs.slice(page * rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((log) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={GetId(log)}
                    >
                      <TableCell>{GetDateString(log)}</TableCell>
                      <TableCell>{GetEventName(log)}</TableCell>
                      <TableCell>{GetDescription(log)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filteredLogs.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

UserActivityView.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default withAuthorization(UserActivityView);
